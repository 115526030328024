import React from 'react'
import { Title } from '@mantine/core'
import InstagramIcon from '@mui/icons-material/Instagram'
import FacebookIcon from '@mui/icons-material/Facebook'

const Footer = () => {
  return (
    <>
      <div style={{ maxWidth: '100%' }}>
        <div style={{ padding: '20px' }}>
          <Title
            size='h2'
            order={2}
            style={{
              textAlign: 'center',
              color: '##333333',
              fontFamily: 'Playfair Display',

              paddingBottom: '20px'
            }}
          >
            Follow us on socials for updates.
          </Title>
          <hr align='center' width='50%' />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              padding: '20px'
            }}
          >
            <a
              href='https://www.instagram.com/matesthatrace/'
              target='_blank'
              rel='noopener noreferrer'
              style={{ textDecoration: 'none', color: '#333333' }}
            >
              <InstagramIcon
                style={{ color: '##333333', width: '60px', height: '60px' }}
              />
            </a>
            <a
              href='https://www.facebook.com/profile.php?id=61552992377992&mibextid=LQQJ4d'
              target='_blank'
              rel='noopener noreferrer'
              style={{ textDecoration: 'none', color: '#333333' }}
            >
              <FacebookIcon
                style={{ color: '##333333', width: '60px', height: '60px' }}
              />
            </a>
          </div>
        </div>
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            padding: '8px',
            color: '#333333',
            fontFamily: 'Playfair Display',
            fontSize: '14px'
          }}
        >
          &copy; 2023 matesthatrace.com.au - All Rights Reserved.
        </div>
      </div>
    </>
  )
}

export default Footer
