import React from 'react'
import { AppShell, Title } from '@mantine/core'
import MessageIcon from './MessageIcon'
import MATES from '../assets/images/MATES.svg'
import Footer from './Footer'

function Appshell() {
  return (
    <AppShell
      style={{ backgroundColor: '#f5f5f5' }}
      navbarOffsetBreakpoint='sm'
      fixed
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Title
          style={{
            textAlign: 'center',
            color: '#333333',
            fontFamily: 'Playfair Display'
          }}
          size='h1'
          order={1}
        >
          Coming soon...
        </Title>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img
            src={MATES}
            alt='mates that race logo'
            style={{
              maxWidth: '100%',
              height: 'auto',
              color: '#B0A49B',
              marginTop: '-20px'
            }}
          />
        </div>
      </div>

      <MessageIcon />

      <div
        style={{ maxWidth: '100%', display: 'flex', justifyContent: 'center' }}
      >
        <Footer />
      </div>
    </AppShell>
  )
}

export default Appshell
