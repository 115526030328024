import React from 'react';
import { MantineProvider, MantineThemeOverride } from '@mantine/core';
import Appshell from './components/Appshell';

function App () {
  const theme: MantineThemeOverride = {
    colors: {
      'background-color': ['#1D3C78', '#2851A3', '#1877F2', '#77A7FF', '#AAC9FF', '#AAC9FF', '#77A7FF', '#1877F2', '#2851A3', '#1D3C78'],
    },
  };

  return (
    <MantineProvider theme={theme}>
      <Appshell />
    </MantineProvider>
  );
}

export default App;
