import React, { useRef } from 'react'
import emailjs from '@emailjs/browser'
import {
  Button,
  Textarea,
  TextInput,
  SimpleGrid,
  CloseButton,
  Box
} from '@mantine/core'

export const GetInTouch = ({ setIsFormVisible }) => {
  const serviceId = process.env.REACT_APP_SERVICE_ID
  const templateId = process.env.REACT_APP_TEMPLATE_ID
  const publicKey = process.env.REACT_APP_PUBLIC_KEY

  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault()

    emailjs.sendForm(serviceId, templateId, form.current, publicKey).then(
      (result) => {
        console.log(result.text)
      },
      (error) => {
        console.log(error.text)
      }
    )
    setIsFormVisible(false)
  }

  return (
    <Box maw={340} mx='auto'>
      <form
        ref={form}
        onSubmit={sendEmail}
        style={{
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '12px'
        }}
      >
        <CloseButton
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px'
          }}
          onClick={() => setIsFormVisible(false)}
        />
        <SimpleGrid cols={{ base: 1, sm: 2 }} mt='xl'>
          <TextInput
            label='Name'
            placeholder='Your name'
            name='user_name'
            variant='filled'
            type='text'
          />
          <TextInput
            label='Email'
            placeholder='Your email'
            name='user_email'
            variant='filled'
            type='email'
          />
        </SimpleGrid>
        <TextInput
          label='Subject'
          placeholder='Subject'
          mt='md'
          name='user_subject'
          variant='filled'
        />
        <Textarea
          mt='md'
          label='Message'
          placeholder='Your message'
          maxRows={10}
          minRows={5}
          autosize
          name='message'
          variant='filled'
        />
        <Button
          type='submit'
          size='md'
          value='Send'
          style={{ marginTop: '20px', width: '100%' }}
        >
          Send message
        </Button>
      </form>
    </Box>
  )
}
