import React, { useState } from 'react'
import { Button } from '@mantine/core'
import MessageTwoToneIcon from '@mui/icons-material/MessageTwoTone'
import { GetInTouch } from './ContactUs'

const MessageIcon = () => {
  const [isFormVisible, setIsFormVisible] = useState(false)

  const openForm = () => {
    setIsFormVisible(true)
  }

  return (
    <div
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px'
      }}
    >
      {isFormVisible ? (
        <GetInTouch setIsFormVisible={setIsFormVisible} />
      ) : (
        <Button
          variant='filled'
          style={{
            borderRadius: '50%',
            width: '60px',
            height: '60px'
          }}
          color='primary'
          onClick={openForm}
        >
          <MessageTwoToneIcon />
        </Button>
      )}
    </div>
  )
}

export default MessageIcon
